.add-members {
  overflow: auto;
  max-height: 400px;
  margin: 10px 5px;
  padding-right: 10px;
}

.add-members::-webkit-scrollbar {
  width: 8px;
  background: #e0e0e0;
}

.add-members::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.add-members::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 15px;
}

.add-members:hover::-webkit-scrollbar {
  width: 8px;
  background: #e0e0e0;
}

.add-members:hover::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.add-members:hover::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
}

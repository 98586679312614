.MuiInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd {
  min-width: 100px;
}
pre {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.box-task-sumary {
  position: relative;
}
.box-task-sumary::after {
  content: '';
  width: 1px;
  background-color: grey;
  position: absolute;
  margin-block: 5px;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
}
/* chat scroll */
.chat-message *::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 24px;
  min-height: 60px;
  border-radius: 12px;
  background-clip: padding-box;
  border: 8px solid transparent;
  opacity: 0.6;
}

.chat-message *::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

/* loading container */
@property --a {
  initial-value: 0deg;
  inherits: false;
  syntax: '<angle>';
}
@property --h {
  initial-value: 0;
  inherits: false;
  syntax: '<number>';
}
.loading-container {
  padding: 1rem 2rem;
  border-style: solid;
  border-width: 1vmin;
  --charge: hsl(var(--h, 0), 80%, 50%);
  -o-border-image: conic-gradient(var(--charge) var(--a), transparent calc(var(--a) + 0.5deg)) 30;
  border-image: conic-gradient(var(--charge) var(--a), transparent calc(var(--a) + 0.5deg)) 30;
  -webkit-animation: load 1s infinite ease-in-out;
  animation: load 1s infinite ease-in-out;
}
@-webkit-keyframes load {
  0%,
  10% {
    --a: 0deg;
    --h: 0;
  }
  100% {
    --a: 360deg;
    --h: 100;
  }
}
@keyframes load {
  0%,
  10% {
    --a: 0deg;
    --h: 0;
  }
  100% {
    --a: 360deg;
    --h: 100;
  }
}

.login-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  padding: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: #21409a;
}

@media(max-width: 767px) {
  .login-page-container {
    background-color: white;
    padding: 0;
  }
}
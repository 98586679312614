.card-display-in-draggable:hover {
  background: #deebff;
  transition: ease-in-out;
  border: 1px solid rgb(67, 67, 238);
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(7, 5, 122, 0.3);
}

.action-card:hover {
  background: #b0b1b3;
  transition: ease-in-out;
}

.column-drag-drop {
  overflow: auto;
  margin: 8px;
}

.column-drag-drop::-webkit-scrollbar {
  width: 8px;
  background: #e0e0e0;
}

.column-drag-drop::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.column-drag-drop::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 15px;
}

.column-drag-drop:hover::-webkit-scrollbar {
  width: 8px;
  background: #e0e0e0;
}

.column-drag-drop:hover::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.column-drag-drop:hover::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
}

.drag-and-drop {
  overflow: auto;
}

.drag-and-drop::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background: #e0e0e0;
}

.drag-and-drop::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.drag-and-drop::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 15px;
}
